<template>
  <communication-log-card
    data-cy="activity-card"
    :background-class="colors.background"
    :comments="log.data.comments"
    class="call-log-card"
  >
    <template #icon>
      <icon-base :icon="IconTelephone" class="text-white" />
    </template>
    <template #content>
      <div
        class="bg-grey-hover px-4 py-2 font-medium rounded-t-md text-gray-500"
      >
        <span class="cursor-pointer" @click="openDeal">
          {{
            log.data.call_sessionable?.business?.business_legal_name ??
            $t("COMMON.DEAL_LINK")
          }}
        </span>
      </div>
      <div class="bg-white p-4 border-b relative z-1">
        <div v-if="!isOpened" class="space-y-4">
          <div class="grid grid-flow-col auto-cols-fr items-center">
            <lf-pill :color-index="currentColorOfStatus" class="w-fit-content">
              <p class="text-xxs uppercase font-semibold">
                {{ callDisposition }}
              </p>
            </lf-pill>
            <div class="justify-self-end">
              <action-menu-wrapper
                :items="menuOptions"
                :offset="offset"
                teleport
                data-cy="action-menu"
              />
            </div>
          </div>
          <lazy-load :options="lazyLoadOptions" persist class="call-recording">
            <call-log-recording
              v-if="log.data.recording"
              :id="log.id"
              :recording="log.data.recording"
            />
          </lazy-load>
          <div v-if="callLogData.body" class="text-headline break-words">
            {{ callLogData.body }}
          </div>
        </div>
        <create-call-note-form
          v-else
          v-on-click-outside="toggleNoteCreation"
          :call-log="log"
          @call-note:create="toggleNoteCreation"
        />
      </div>
    </template>
    <template #footer>
      <div class="bg-white flex w-full space-y-2 justify-between">
        <div class="flex items-center wrapper">
          <avatar-with-name :user="log.data.user" data-cy="user-name" />
          <div class="flex items-center space-x-1">
            <icon-base
              data-cy="arrow-icon"
              :icon="IconArrow"
              :class="colors.text"
              width="12"
              height="12"
            />
            <p class="text-xs text-label" data-cy="call-status">
              {{ callStatus }}
            </p>
          </div>
          <avatar-with-name
            v-if="callLogData.callee?.name"
            :user="{ first_name: callLogData.callee?.name }"
            data-cy="callee-name"
          />
          <div v-if="callLogData.to" class="flex items-center space-x-1">
            <span>&#8226;</span>
            <p class="text-xs text-label" data-cy="call-to">
              {{ callLogData.to }}
            </p>
          </div>
          <div v-if="log.data?.created_at" class="flex items-center space-x-1">
            <span>&#8226;</span>
            <date-time :date="log.data?.created_at" />
          </div>
          <lf-pill
            v-if="callDisposition && !callLogData.body"
            :color-index="currentColorOfStatus"
          >
            <p class="text-xxs uppercase font-semibold">
              {{ callDisposition }}
            </p>
          </lf-pill>
        </div>
        <div
          v-if="!callLogData.body && !isOpened"
          class="cursor-pointer self-center edit-call"
        >
          <icon-base :icon="IconEdit" @click="toggleNoteCreation" />
        </div>
      </div>
    </template>
  </communication-log-card>
</template>

<script lang="ts">
import { computed, provide, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { CallLog } from "@/models/communicationLogs";
import { COLORS_BY_DISPOSITION_NOTE_STATUS } from "@/helpers/constants/colors";
import { useRouter } from "vue-router";
import { CallSessionStatus, LogEndpointType } from "@/enums/communicationLogs";
import { useCommunicationStore } from "@/stores/communication";
import { ROUTE_DEAL_PROGRESS } from "@/router/routes";
import { CommunicationLogContextKey } from "@/helpers/symbols";
import type { LazyLoadOptions } from "@/models/common";

interface Props {
  log: CallLog;
}
</script>

<script setup lang="ts">
import CommunicationLogCard from "@/components/comunicationLogs/communication/CommunicationLogCard.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import CreateCallNoteForm from "@/components/comunicationLogs/forms/CreateCallNoteForm.vue";
import IconArrow from "@/components/icons/IconArrow.vue";
import IconTelephone from "@/components/icons/IconTelephone.vue";
import ActionMenuWrapper from "@/components/ui/ActionMenuWrapperV2.vue";
import CallLogRecording from "@/components/comunicationLogs/communication/CallLogRecording.vue";
import AvatarWithName from "@/components/ui/AvatarWithName.vue";
import DateTime from "@/components/ui/ConvertibleDateTime.vue";
import LazyLoad from "@/components/LazyLoad.vue";

const DEFAULT_COLORS = {
  background: "bg-gray-100",
  text: "text-gray-500"
} as const;

const STATUS_RELATED_COLORS: Record<
  CallSessionStatus,
  { background: string; text: string }
> = {
  [CallSessionStatus.Unreachable]: {
    background: "bg-error",
    text: "text-error"
  },
  [CallSessionStatus.InProgress]: {
    background: "bg-warning",
    text: "text-warning"
  },
  [CallSessionStatus.Answered]: {
    background: "bg-success",
    text: "text-success"
  }
};

const offset = { left: -42, top: 43 };

const store = useCommunicationStore();
const props = defineProps<Props>();

provide(CommunicationLogContextKey, {
  type: LogEndpointType.CallSession,
  logId: props.log.data.id
});

const { t } = useI18n();
const router = useRouter();
const isOpened = ref(false);

const lazyLoadOptions: LazyLoadOptions = {
  style: {}
};

const callLogData = computed(() => props.log.data);

const callDisposition = computed(
  () =>
    callLogData.value.call_disposition?.replace(/_/g, " ") ??
    t("ACTIVITY.SELECT_CALL_DISPOSITION")
);

const currentColorOfStatus = computed(() => {
  return COLORS_BY_DISPOSITION_NOTE_STATUS[
    callLogData.value
      ?.call_disposition as keyof typeof COLORS_BY_DISPOSITION_NOTE_STATUS
  ];
});

const colors = computed(
  () => STATUS_RELATED_COLORS[props.log.data.status] ?? DEFAULT_COLORS
);

const statusLocaleMap = computed(() => {
  const map: Record<CallSessionStatus, string> = {
    [CallSessionStatus.Unreachable]: t("ACTIVITY.CONTACTS.UNREACHABLE"),
    [CallSessionStatus.InProgress]: t("ACTIVITY.CONTACTS.CALLED"),
    [CallSessionStatus.Answered]: t("ACTIVITY.CONTACTS.REACHED")
  };
  return map;
});

const callStatus = computed(() => {
  const status = props.log?.data.status;
  return statusLocaleMap.value[status];
});

const toggleNoteCreation = () => {
  isOpened.value = !isOpened.value;
  store.toggleIsEditingNoteStatus(isOpened.value);
};

const dealRoute = computed(() =>
  router.resolve({
    name: ROUTE_DEAL_PROGRESS,
    params: { id: props.log.data?.call_sessionable.application_id?.toString() }
  })
);

const openDeal = () => {
  if (!props.log.data?.call_sessionable.application_id) {
    return;
  }
  window.open(dealRoute.value.href, "_blank");
};

const menuOptions = [
  {
    label: t("COMMON.EDIT"),
    handler: toggleNoteCreation,
    class: "menu-item"
  }
];
</script>

<style scoped>
.menu-item {
  @apply px-4 py-1 hover:bg-blue-100 cursor-pointer;
}

.call-log-card {
  container-type: inline-size;
  container-name: call-log-card;
}
.call-footer {
  container-type: inline-size;
  container-name: call-footer;
}

@container call-log-card (max-width: 870px) {
  :deep() {
    .space-x-4 {
      @apply space-x-2;
    }
    .edit-call {
      @apply flex self-end p-1-5 mt-0 !important;
    }
    .comments-toggle {
      @apply self-end ml-0 !important;
    }
  }
}

@container call-footer (min-width: 750px) {
  :deep() {
    > .wrapper {
      @apply space-x-2;
    }
  }
}

@container call-footer (max-width: 750px) {
  :deep() {
    > .wrapper {
      @apply flex-wrap -ml-1 -mr-1;
      > div {
        @apply p-1;
      }
    }
  }
}

.call-recording {
  container-type: inline-size;
  container-name: call-recording;
}

@container call-recording (max-width: 650px) {
  :deep() {
    .recording {
      @apply flex-wrap;
    }
    .w-2\/5,
    .w-3\/5 {
      @apply w-full;
    }
    .w-2\/5 {
      @apply mb-2;
    }
  }
}
</style>
