import { CallDisposition } from "@/enums/communicationLogs";

export const COLORS_BY_DISPOSITION_NOTE_STATUS: Record<
  CallDisposition,
  number
> = {
  [CallDisposition.no_answer]: 15, // grey
  [CallDisposition.vm]: 5, // orange (status warning)
  [CallDisposition.made_contact]: 0, // green (status success)
  [CallDisposition.spoke_with_dm]: 21, // secondary green 1
  [CallDisposition.bad_number]: 2, // red (status error)
  [CallDisposition.discovery_call]: 4 // dark blue
};

export const NOTE_COLORS_BY_ID = {
  "0": 15, // grey
  "1": 5, // orange (status warning)
  "2": 0, // green (status success)
  "3": 21, // secondary green 1
  "4": 2, // red (status error)
  "5": 4 // dark blue
};
